.login-main {
  background: url("../../img/loginbackground.jpg") no-repeat center center;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-card {
  text-align: center;
  h2 {
    color: white;
  }
}
.login-body {
  width: 400px;
  height: 200px;
  margin: 50px 0;
  .login-label {
    color: white;
  }
}
