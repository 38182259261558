.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $mainWhite;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  border-right: 2px solid $mainGrey;
  .sidebar-logo {
    text-align: center;
    margin: 20px 0;
    img {
      width: 180px;
    }
  }
  .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
}
