.btn {
  padding: 10px 1rem;
  margin: 5px;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;

  &:hover {
    cursor: pointer;
  }
}
.signupbtn {
  color: $mainWhite;
  background-color: $mainOrange;
  a {
    color: white;
  }
  &:hover {
    background-color: $darkOrange;
  }
}

.signinbtn {
  color: $mainBlack;
  background-color: white;

  border: 1px solid #e3e9f0;
  &:hover {
    background-color: #e3e9f0;
  }
}
.signoutbtn {
  color: $mainWhite;
  background-color: $mainOrange;
  a {
    color: white;
  }
  &:hover {
    background-color: $darkOrange;
  }
}
.whtebtn {
  height: 40px;
  color: $mainOrange;
  background-color: white;
  padding: 10px 25px;
  margin: 5px;
  border-radius: 100px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: $mainWhite;
    background-color: $mainOrange;
    cursor: pointer;
  }
}

.sidebar-button {
  width: 180px;
  height: 40px;
  color: white;
  background-color: $mainOrange;
}
