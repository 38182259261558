.opportunitiecard {
  display: flex;
  justify-content: space-between;
  background-color: #FF8B4C;
  padding: 20px;
  margin: 20px;

  button {
    align-self: flex-end;
    height: 50px;
  }
}
