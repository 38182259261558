.input-group {
  margin-bottom: 15px;
  width: 100%;

  label {
    display: block;
    margin-bottom: 5px;
  }
  input,
  select,
  textarea {
    font-family: $fontFamily;
    font-size: 16px;
    padding: 0.5rem 0.4rem;
    width: 100%;
    border: 1px solid #eee;

    &:focus {
      outline: none;
      border-color: $mainOrange;
    }
  }
}
.form {
  max-width: 900px;
  margin: 0 auto;
}
.multiselect-container {
  .chip {
    background: white;
  }
  .highlightOption {
    background: white;
  }
  .optionContainer li {
    &:hover {
      background: white;
    }
  }
}

.form-link {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  color: #333;
}
