.signup-container {
  min-height: 85vh;
  background-image: linear-gradient(to bottom right, #f47525, #fbb519);
  display: flex;
  align-items: center;
  justify-content: center;
  .section-1 {
    margin: 10px 0px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .section-1-title {
      text-align: center;
      justify-self: flex-start;
    }
    .section-1-content {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      //background-image: linear-gradient(to bottom right, #fbb519, #f47525);
      .section-1-form {
        min-width: 40%;
      }
      .section-1-img {
        width: 40vw;
        text-align: center;
        img {
          width: 20vw;
        }
      }
    }
  }
}
